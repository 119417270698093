.all{
  height: 98vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #051e3f;
}
.card{
  height: 70%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}
.card img{
  width: 13rem;
}
.Heading{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.Heading h1{
  margin: 7px;
}